<template>
  <v-file-input
    v-model="files"
    :multiple="multiple"
    show-size
    label="Lataa"
    accept=".jpg,.jpeg,.png"
    outlined
    dense
    persistent-hint
    prepend-icon="mdi-camera"
    :hint="`Max. ${maxSize} kB / ${maxSize / 1000} MB, .jpg .jpeg .png`"
    :loading="uploading"
    :disabled="uploading"
    @change="selectFiles"
  ></v-file-input>
</template>

<script>
import mixins from "../../mixins/mixins";
import { mapMutations, mapState } from "vuex";
import { apiAgent } from "../../services/apiAgent";
import axiosMethods from "@/mixins/axios";

export default {
  mixins: [mixins],

  props: {
    multiple: {
      type: Boolean,
      default: true,
    },
    model: {
      type: String,
      default: "",
    },
    bucket: {
      type: String,
      default: "",
    },
    contractId: {
      type: String,
      default: "",
    },
  },

  data: function () {
    return {
      files: [],
      maxSize: 5000,
      allowedTypes: ["image/jpeg", "image/jpg", "image/png"],
    };
  },

  computed: {
    ...mapState("image", ["images", "uploading"]),
  },

  methods: {
    ...mapMutations("image", ["setImages", "setUploading", "setUploadProgress"]),
    ...mapMutations("contract", ["setRentalContractImages"]),
    ...mapMutations("account", ["replaceImages"]),

    async selectFiles(newFiles) {
      let files = [];
      // validate length
      if (this.multiple && newFiles.length > 15) {
        return this.showPopup("Voit ladata kerralla 15 kuvaa.", "error");
      }

      // If single file
      if (!this.multiple && newFiles) {
        newFiles.src = URL.createObjectURL(newFiles);
        newFiles.errorMessage = this.validate(newFiles);
        files = [newFiles];
      } else if (this.multiple) {
        // Set files list that is shown to user (all files)
        files = [
          ...this.images,
          ...newFiles.map((el) => {
            el.src = URL.createObjectURL(el);
            el.errorMessage = this.validate(el);
            return el;
          }),
        ];
      }

      // Set only valid files to uploadFiles array
      let validUpFiles = files.filter((el) => el.errorMessage === null);

      const formData = new FormData();

      if (validUpFiles.length > 0) {
        // Append to formData
        for (const file of validUpFiles) {
          formData.append("files", file, encodeURIComponent(file.name));
        }

        try {
          // Upload start
          this.setUploading(true);

          // Upload to server and s3
          const res = await apiAgent.post(
            `/api/v1/image/upload-images/${this.getDocumentId()}?bucket=${this.bucket}&model=${
              this.model
            }`,
            formData,
            {
              onUploadProgress: (event) =>
                this.setUploadProgress(Math.floor((event.loaded * 100) / event.total)),
            }
          );

          const dataString = JSON.stringify(res.data);

          // If error
          if (dataString.includes("ERROR:")) {
            const errorMessage = dataString.split("ERROR: ")[1];
            throw new Error(errorMessage);
          }

          // Set to store
          this.setImages(res.data.images);

          // set contract images to table item
          if (this.model == "rental-contract") {
            this.setRentalContractImages({
              images: res.data.images,
              contractId: this.getDocumentId(),
            });
          }
          // set account images to currentAccount
          if (this.model == "account") {
            this.replaceImages(res.data.images);
          }

          // End of uploading
          this.setUploading(false);
          this.showPopup("Lataaminen onnistui", "success");
        } catch (err) {
          // End of uploading
          this.setUploading(false);
          const error = await axiosMethods.validateRequest(err);
          this.showPopup(error, "error");
        }
      }

      // Set error images in their own array
      files.forEach((el) => {
        if (el.errorMessage) this.$store.state.image.errorFiles.push(el);
      });
    },

    getDocumentId() {
      let id;
      switch (this.model) {
        case "apartment":
          id = this.$route.params.id;
          break;
        case "condominium":
          id = this.$route.params.id;
          break;
        case "rental-contract":
          id = this.contractId;
          break;
        case "account":
          id = this.$store.state.account.currentUser.currentAccount._id;
          break;
        default:
          id = "";
      }

      return id;
    },

    validate(file) {
      // Validate only files from local machine, not from database
      if (file._id) return "Ei tarvitse prosessoida...";

      if (file.size / 1000 > this.maxSize) return "Tiedosto on liian suuri.";

      if (!this.allowedTypes.includes(file.type))
        return "Tiedostomuoto ei kelpaa. Lataa .jpg, .jpeg, .png tiedosto.";

      return null;
    },
  },
};
</script>

<style scoped></style>
