<template>
  <div>
    <v-row dense :class="getRowStyle()">
      <v-col cols="12" :sm="sm" :md="md" :lg="lg">
        <!-- Upload component-->
        <image-upload
          :bucket="bucket"
          :model="model"
          :contractId="contractId"
        ></image-upload> </v-col
    ></v-row>

    <!-- Progressbar upload -->
    <div v-if="uploading && uploadProgress < 100" :class="getTextStyle()">
      <v-progress-linear v-model="uploadProgress" height="25">
        <strong>{{ uploadProgress }} %</strong>
      </v-progress-linear>
    </div>

    <!-- Waiting -->
    <v-row
      dense
      v-if="uploading && uploadProgress == 100"
      class="fill-height"
      align-content="center"
      :justify="getWaitingStyle()"
    >
      <v-col class="text-subtitle-1" :class="getTextStyle()" cols="12"> Ladataan kuvia... </v-col>
      <v-col cols="6">
        <v-progress-linear color="primary" indeterminate rounded height="6"></v-progress-linear>
      </v-col>
    </v-row>

    <!-- Real images -->
    <draggable
      v-if="previewImages"
      class="row ml-1 mr-1 mt-1 mb-5"
      :class="getRowStyle()"
      v-model="images"
      @change="sortList"
      handle=".v-card"
    >
      <v-col
        class="pa-3"
        v-for="(image, index) in images"
        :key="'a' + index"
        cols="12"
        sm="6"
        md="4"
        lg="3"
        xl="2"
      >
        <div style="display: flex; justify-content: space-between">
          <v-btn @click.stop="viewImage(index)" icon
            ><v-icon small>mdi-arrow-expand-all</v-icon></v-btn
          >
          <v-btn @click.stop="downloadImage(image)" icon><v-icon small>mdi-download</v-icon></v-btn>
          <v-btn @click.stop="deleteImg(image)" icon
            ><v-icon small color="error">mdi-delete</v-icon></v-btn
          >
        </div>
        <v-card class="h-full" style="cursor: move">
          <v-card-text
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0;
              height: 100%;
            "
          >
            <v-img :src="imageUrls[index]" contain height="200" @error="reloadImage(image, index)">
            </v-img>
          </v-card-text>
        </v-card>
      </v-col>
    </draggable>

    <!-- Image list, no preview -->
    <div v-if="!previewImages" class="mt-1">
      <div v-if="!showFullImage">
        <v-card v-for="(image, index) in images" :key="'b' + index">
          <v-card-text class="list-object-wrapper">
            <div class="list-object" style="cursor: pointer" @click="showImage(image)">
              <v-icon medium class="pr-1">mdi-camera</v-icon>
              <p>{{ truncateString(image.name, 20) }}</p>
            </div>

            <v-icon @click="deleteImg(image)" color="error">mdi-delete</v-icon>
          </v-card-text>
        </v-card>
      </div>

      <div v-if="showFullImage">
        <div style="cursor: pointer; max-width: 130px" @click="showFullImage = false">
          <v-icon class="mt-1 mb-1">mdi-arrow-left</v-icon> Palaa kuviin
        </div>
        <v-img :src="signedUrl" contain max-height="400px"> </v-img>
      </div>
    </div>

    <!-- Error files -->
    <div v-if="errorFiles.length > 0">
      <h3 class="mt-2 ml-2" :class="getTextStyle()">Näitä ei ladattu</h3>
      <v-row class="ml-1 mr-1" :class="getRowStyle()">
        <v-col
          class="pa-1"
          v-for="(file, index) in errorFiles"
          :key="'c' + index"
          cols="12"
          sm="6"
          md="4"
          lg="3"
          xl="2"
        >
          <v-card class="h-full" :class="{ 'error-msg': file.errorMessage }">
            <v-card-text style="padding: 0">
              <v-img :src="file.src" contain max-height="170px"> </v-img>
              <v-btn @click="removeErrorFile(file.src)" icon
                ><v-icon color="error" small icon>mdi-delete</v-icon></v-btn
              >

              <p
                v-if="file.errorMessage"
                class="pa-1 error--text"
                style="text-align: center"
                v-text="file.errorMessage"
              ></p>
              <!-- <p v-text="truncateString(file.name, 15)"></p> -->
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <!-- No images -->
    <v-col v-if="(images.length == 0) & !uploading"
      ><h2 :class="getTextStyle()">Ei kuvia</h2></v-col
    >

    <!-- Carousel -->
    <view-image-dialog
      v-model="imageDialog"
      :images="images"
      :imageUrls="imageUrls"
      imageType="image"
      ref="imageDialog"
    ></view-image-dialog>
  </div>
</template>

<script>
import ImageUpload from "./UploadImages.vue";
import mixins from "../../mixins/mixins";
import { mapState, mapActions, mapMutations } from "vuex";
import draggable from "vuedraggable";
import ViewImageDialog from "./ViewImageDialog";
import axiosMethods from "../../mixins/axios";
import { apiAgent } from "@/services/apiAgent";

export default {
  title: "Kohdekuvat",

  props: {
    model: {
      type: String,
      default: "",
    },
    bucket: {
      type: String,
      default: "",
    },
    align: {
      type: String,
      default: "center",
    },
    sm: {
      type: String,
      default: "6",
    },
    md: {
      type: String,
      default: "4",
    },
    lg: {
      type: String,
      default: "3",
    },
    contractId: {
      type: String,
      default: "",
    },
    previewImages: {
      type: Boolean,
      default: true,
    },
  },

  components: { ImageUpload, draggable, ViewImageDialog },

  mixins: [mixins],

  data() {
    return {
      showFullImage: false,
      imageUrls: [],
      imageDialog: false,
    };
  },

  computed: {
    ...mapState("image", ["uploading", "uploadProgress", "errorFiles", "signedUrl"]),

    images: {
      get() {
        return this.$store.state.image.images;
      },
      set(arr) {
        this.$store.state.image.images = arr;
      },
    },
  },

  watch: {
    signedUrl(val) {
      if (val) {
        this.showFullImage = true;
      }
    },

    images: {
      handler: "updateImageUrls",
      immediate: true,
    },
  },

  methods: {
    ...mapActions("image", ["deleteImage", "sortImages", "getSignedUrl", "getImageUrl"]),
    ...mapMutations("image", ["removeErrorFile"]),
    ...mapMutations("contract", ["replaceContract"]),

    viewImage(index) {
      this.$refs.imageDialog.updateImageIndex(index);
      this.imageDialog = true;
    },

    updateImageUrls() {
      this.imageUrls = this.images.map((img) => this.getImageCloudFrontUrl(img));
    },

    reloadImage(img, index) {
      setTimeout(() => {
        const imageUrl = this.getImageCloudFrontUrl(img);
        this.$set(this.imageUrls, index, imageUrl);
      }, 2500);
    },

    deleteImg(image) {
      const data = {
        url: `/api/v1/image/delete-image/${this.getDocumentId()}?key=${image.key}&bucket=${
          image.bucket
        }&model=${this.model}`,
        key: image.key,
      };

      this.deleteImage(data);
    },

    async downloadImage(file) {
      try {
        const encodedKey = this.encodeKey(file.key);

        const res = await apiAgent({
          method: "GET",
          url: `/api/v1/${this.model}/getFile?key=${encodedKey}&bucket=${file.bucket}&mimeType=${file.mimeType}`,
          responseType: "blob",
        });

        this.downloadFile(res.data, decodeURIComponent(file.name));
      } catch (err) {
        const error = await axiosMethods.validateRequest(err);
        this.showPopup(error, "error");
      }
    },

    encodeKey(key) {
      const normalizedKey = key.normalize("NFC");
      return encodeURIComponent(normalizedKey);
    },

    sortList() {
      this.sortImages({
        documentId: this.getDocumentId(),
        images: this.images,
        model: this.model,
      });
    },

    getDocumentId() {
      let id;
      switch (this.model) {
        case "apartment":
          id = this.$route.params.id;
          break;
        case "condominium":
          id = this.$route.params.id;
          break;
        case "rental-contract":
          id = this.contractId;
          break;
        default:
          id = "";
      }

      return id;
    },

    showImage(img) {
      this.getSignedUrl(img);
    },

    getRowStyle() {
      switch (this.align) {
        case "center":
          return "justify-center";
        case "left":
          return "justify-start";
        case "right":
          return "justify-end";
        default:
          return "justify-center";
      }
    },

    getWaitingStyle() {
      switch (this.align) {
        case "center":
          return "center";
        case "left":
          return "start";
        case "right":
          return "end";
        default:
          return "center";
      }
    },

    getTextStyle() {
      switch (this.align) {
        case "center":
          return "text-center";
        case "left":
          return "text-left";
        case "right":
          return "text-right";
        default:
          return "text-center";
      }
    },
  },
};
</script>

<style scoped>
.image-wrapper {
  display: flex;
  align-items: center;
}

.error-msg {
  border: 2px solid var(--v-error-base);
}

.v-card {
  transition: opacity 0.4s ease-in-out;
}

.list-object {
  max-width: 300px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.list-object-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.list-object-wrapper:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}
</style>
